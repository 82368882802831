@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
	margin: 0rem;
	padding: 0rem;
	border: none;
	outline: none;
}

@font-face {
	font-family: "icofont";
	src: url("../../fonts/icofont.eot");
	src: url("../../fonts/icofontd41d.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/icofont.woff") format("woff"),
		url("../../fonts/icofont.ttf") format("truetype"),
		url("../../fonts/icofont.svg#icofont") format("svg");
	font-weight: $font-regular;
	font-style: normal;
}

@font-face {
	font-family: "simple-line-icons";
	src: url("../../fonts/simple-Line-Icons.eot");
	src: url("../../fonts/simple-Line-Iconsd41d.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/simple-Line-Icons.woff") format("woff"),
		url("../../fonts/simple-Line-Icons.ttf") format("truetype"),
		url("../../fonts/simple-Line-Icons.svg#simple-Line-Icons") format("svg");
	font-weight: $font-regular;
	font-style: normal;
}

@font-face {
	font-family: 'themify';
	src: url('../../fonts/themify9f24.eot?-fvbane');
	src: url('../../fonts/themifyd41d.eot?#iefix-fvbane') format('embedded-opentype'),
		url('../../fonts/themify9f24.woff?-fvbane') format('woff'),
		url('../../fonts/themify9f24.ttf?-fvbane') format('truetype'),
		url('../../fonts/themify9f24.svg?-fvbane#themify') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "SignericaMedium";
	src: url("../../fonts/SignericaMedium.eot");
	src: url("../../fonts/SignericaMediumd41d.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/SignericaMedium.woff") format("woff"),
		url("../../fonts/SignericaMedium.ttf") format("truetype"),
		url("../../fonts/SignericaMedium.svg#SignericaMedium") format("svg");
	font-weight: $font-regular;
	font-style: normal;
}


body {
	font-family: $font-family-opensans;
	font-size: 0.875rem;
	color: $clr-bg-portfolio;
	line-height: 1.6em;
	font-weight: $font-xlight;
	background: $clr-white;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	padding-right: 0rem !important;
	overflow: visible !important;
}

.bordered-layout .page-wrapper {
	padding: 0rem 3.125rem 0rem;
}

a {
	text-decoration: none;
	cursor: pointer;
	color: $clr-green;
}

button,
a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
	font-weight: normal;
	margin: 0rem;
	background: none;
	line-height: 1.6em;
	font-family: $font-family-opensans;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

textarea {
	overflow: hidden;
}

p {
	position: relative;
	line-height: 1.8em;
}

.auto-container {
	position: static;
	max-width: 75rem;
	/* padding: 0rem 0.9375rem; */
	padding: 0rem 0rem;
	margin: 0 auto;
}

.medium-container {
	max-width: 53.125rem;
}

.page-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	min-width: 18.75rem;
}

ul,
li {
	/* list-style:none; */
	padding: 0rem;
	margin: 0rem;
}
// ol, ul {
//     padding-left: 0 !important;
// }

img {
	display: inline-block;
	max-width: 100%;
}
.hyperlink {
	color: $clr-hyperlink;
	cursor: pointer;
}
img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}
.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}
.mb-n30 {
	margin-bottom: -1.875rem;
}

.mt-lg-30 {
	margin-top: 1.875rem;
}

.mt-lg-100 {
	margin-top: 6.25rem;
}

.mt-lg-70 {
	margin-top: 4.375rem;
}

.mt-40 {
	margin-top: 2.5rem !important;
}

.mt-60 {
	margin-top: 3.75rem !important;
}

.mb-25 {
	margin-bottom: 1.5625rem
}

.mb-60 {
	margin-bottom: 3.75rem;
}

.mb-20 {
	margin-bottom: 1.25rem !important;
}
.mb-30 {
	margin-bottom: 1.875rem !important;
}
.mtb-30 {
	margin: 1.875rem 0;
}

.mtb-40 {
	margin: 1.25rem 0 2.5rem !important;
}

.mb10 {
	margin-bottom: 0.625rem !important;
}

.mt35 {
	margin-top: 2.25rem !important;
}

.mt-25 {
	margin-top: 2.25rem !important;
}
.ml-15 {
	margin-left: 0.9375rem !important;
}

.ml-22 {
	margin-left: 1.375rem !important;
}
.ml-20 {
	margin-left: 1.25rem !important;
}

.mb-40 {
	margin-bottom: 2.5rem !important;
}
.padd0 {
	padding: 0;
}
.ptb-010 {
	padding-top: 0 !important;
	padding-bottom: 0.625rem;
}

.pb-100 {
	padding-bottom: 6.25rem !important;
}
.mt-70 {
	margin-top: 4.375rem !important;
}
.padd-0 {
	padding: 0;
}
.mr-12 {
	margin-right: 2.8125rem;
}

.mr-2 {
	margin-right: 2.25rem;
}

.mr-26 {
	margin-right: 3.75rem;
}

.mt-4em {
	margin-top: 4em !important;
}
.mt-35 {
	margin-top: 2.25rem !important;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}
.centered {
	text-align: center;
}
.row {
	display: flex;
}

.react-pdf__Page__canvas {
	margin: 0 auto !important;
    width: 90% !important;
}

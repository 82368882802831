/* portfolio section */

.portfolio-section {
	background-color: $clr-bg-main;
	padding-bottom: 4.375rem;
	margin-top: -3.75rem;
	/* min-height: 100vh; */
	/* position: absolute; */
    /* left: 0rem;
    top: 0rem;
    right: 0rem;
    bottom: 0rem; */
}
.inner-box.portfolio {
	min-height: 30rem;
	width: 32.5rem;
	/* width: 31.5625rem; */
	overflow: hidden;
	margin: -1.5625rem 2.5rem 4.375rem;
	background-color: $clr-bg-portfolio;
	border: 0rem solid $clr-bg-portfolio;
	padding: 0;
	box-shadow: $clr-box-shadow;
}

.inner-box.portfolio:hover {
	background-color: $clr-box-hover;
	border: 0rem solid $clr-box-hover;
}
.portfolio-filter {
	margin-top: 9em;
	margin-bottom: 0.9375rem;
	padding: 0 0.9375rem;
}
.portfolio-border {
	border-top: 0.1875rem solid $clr-green;
	margin: 0.625rem 0 0.9375rem;
}

p.portfolio-heading {
	color: $clr-text-five;
	font-size: 1rem;
	text-align: left;
	line-height: 1.3;
	margin-bottom: 0.3125rem;
	font-weight: $font-regular;
	font-family: $font-family-opensans;
}

.portfolio-border-top {
	border-top: 0.0625rem solid $clr-border-three;
	padding: 0.625rem 0rem 0.25rem;
}
.portfolio-details {
	padding: 0.9375rem 1.5625rem;
	/* margin-bottom: 5.625rem; */
	overflow: hidden;
	height: 25.9375rem;
}

.text-image {
	position: absolute;
	top: 1.3125rem;
	left: 1.75rem;
	color: $clr-white;
	text-transform: uppercase;
	font-family: $font-family-montserrat;
	font-size: 0.875rem;
	font-weight: $font-semibold;
}

.text-image1 {
	position: absolute;
	top: 1.1875rem;
	left: 1.75rem;
	color: $clr-white;
	text-transform: uppercase;
	font-family: $font-family-montserrat;
	font-size: 0.875rem;
	font-weight: $font-medium;
}

.port-risk-img {
	margin-top: 0.9375rem !important;
}

.portfolio-details h2.port-heading {
	font-size: 1.375rem;
	text-align: left;
	color: $clr-text-three;
	font-weight: $font-bold;
	/* letter-spacing: 0.0625rem; */
	font-family: $font-family-opensans;
	line-height: 1.875rem;
}

.portfolio-border-top p.port-desc {
	line-height: 1.5rem;
	margin-left: -0.9375rem;
	font-family: $font-family-opensans;
	font-size: 1rem;
	font-weight: $font-semibold;
}

p.port-advisor {
	line-height: 1.8;
}

.align-right {
	text-align: right;
}

p.portheading {
	margin-top: 6.8em;
	font-size: 1.8125rem;
	line-height: 1.75rem;
	color: $clr-green;
	font-weight: $font-bold;
	font-family: $font-family-montserrat;
	margin-bottom: 0.3125rem;
}

.port-filter-border {
	border-bottom: 0.0625rem solid $clr-border-one !important;
	/* width: 91%; */
	margin-left: 3.4375rem;
	padding: 0;
}

/*risk color gradient*/
.low-risk {
	width: 100%;
	height: 1.875rem;
	background: $clr-lr;
	background-size: 120% 120%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}
.medium-risk {
	width: 100%;
	height: 1.875rem;
	background: $clr-mr;
    background-size: 120% 120%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}
.high-risk {
	width: 100%;
	height: 1.875rem;
	background: $clr-hr;
	background-size: 120% 120%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}
/* Animation */
@-webkit-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
/* Animation */

/*risk color gradient*/

p.portfolio-date {
	margin-left: 0rem !important;
}
.graph-icon {
	background-image: url("../../images/go-to-portfolio.png");
	width: 1.5625rem;
	height: 0.875rem;
	margin: 0.5rem 0.75rem 0;
}

.inner-box.portfolio:hover .graph-icon {
	background-image: url("../../images/go-to-portfolio-hover.png");
	width: 1.5625rem;
	height: 0.875rem;
	margin: 0.5rem 0.75rem 0;
}


p.port-desc.advisor-name {
	margin-left: -0.625rem;
	text-transform: capitalize;
	font-family: $font-family-opensans;
	font-size: 1rem;
	font-weight: $font-semibold;
}

p.port-desc.number-stocks {
	margin-left: -0.375rem;
}